import React, { forwardRef, Ref } from "react";
import banner from "../../assets/images/banner.jpeg";
interface SummaryProps {
  classes: any;
  details: string;
  title: string;
}

const Summary = forwardRef<HTMLDivElement, SummaryProps>((props, ref) => {
  const title = String(props?.title);
  const summery = String(props?.details);
  const classes = props.classes;
  return (
    <>
      <h1>{title}</h1>
      <div className={classes.section1}>
        <div className={classes.itemImg}>
          <img src={banner} alt="images" className={classes.img} />
        </div>
        <div className={classes.item}>
          <p>{summery}</p>
        </div>
      </div>
    </>
  );
});

Summary.displayName = "Summary";

export default Summary;
