/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-self-assign */
/* eslint-disable prefer-const */
import {
  Box,
  Card,
  Col,
  Container,
  Flex,
  Grid,
  Title,
  Text,
  Loader,
  Overlay,
  Image,
  ActionIcon,
  Dialog,
  Group,
  Textarea,
  Button,
  Skeleton,
} from "@mantine/core";
import "../dashboard/style.css";
import { Fragment, MutableRefObject, useEffect, useRef, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Play from "../../assets/svg/play.svg";
import Pause from "../../assets/svg/pause.svg";
import Wave from "../../component/wave";
import CarouselFrame from "../../component/carouselFrame";
import ReplySharpIcon from "@mui/icons-material/ReplySharp";

import Graph from "../../component/graph/Graph";
import TextTyped from "../../component/TextTyped";
import PunctuationTyped from "../../component/PunctuationTyped";
import VisionAnalysis from "../../component/visionAnalysis";
import RoundChart from "../../component/roundChart";
import _ from "lodash";
import Sentiment from "../../component/sentiments";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import { projectActions } from "./redux";
import { getProjectReq, messageReq } from "./service/service";
import "./style.css";
import { MessageRounded } from "@mui/icons-material";
import { convertToMinutesAndSeconds } from "../../constant/constant";
import { useNavigate } from "react-router-dom";
import { transformDataToGraphFormat } from "../../component/graph/functions/functions";
import {
  findClosestTimestamp,
  findNearestTimestampsFromThumbnails,
  findEmotionIndex,
  convertToTimestampFormat,
  emotionBreakdownForGraph,
} from "./helpers/functions";
import { Carousel } from "@mantine/carousel";

let viewSpeech = "";
let prevViewSpeech = "";
let prevPunctuation = "";
let punctuation = "";
let roundGraphArr = [];
let interval;
let sentiments = [];

function ProjectDetail(this: any) {
  const isAudioReady = useSelector(
    (state: any) => state.projectReducer.isAudioDownload
  );

  const dispatch = useDispatch();
  const { audioReq } = bindActionCreators(projectActions, dispatch);
  const [projectData, setProjectData] = useState({}) as any;
  const [isOpen, setOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [chats, setChats] = useState<
    { role: string; message: string; createAt: number; ts?: number }[]
  >([]);
  const [isShiftPress, setShiftPress] = useState(false);
  const videoRef = useRef() as MutableRefObject<HTMLVideoElement>;
  const {
    audio_url,
    thumbnail,
    video_url,
    speech_to_text,
    report,
  }: {
    audio_url?: any;
    thumbnail?: any;
    video_url?: any;
    link?: any;
    speech_to_text?: any;
    type?: any;
    report?: any;
  } = projectData;
  const [opinionSnippets, setOpinionSnippets] = useState([]);
  let [roundGraphObjArr, setRoundGraphObjArr] = useState([]);
  const [isSendBtnDisabled, setSendBtnDisabled] = useState(false);
  const [sentimentArr, setSentimentArr] = useState([]);
  const [rowCalcWithTotalNumOfEmotion, setRowCalcWithTotalNumOfEmotion] =
    useState([]);
  const [videoPlayed, setVideoPlayed] = useState(true);
  const navigate = useNavigate();
  const [isPlay, setPlay] = useState(false);
  const [textInterval, setTextInterval] = useState(0);
  const [punctuationInterval, setPunctuationInterval] = useState(0);
  const [time, setTime] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [click, setClick] = useState("");
  const [sentimentAttribute, setSentimentAttribute] = useState([]);
  const [emotion, setEmotion] = useState("");
  const [trueValueScore, setScore] = useState([]);
  const [isClear, setIsClear] = useState(false);

  let prevTimeStamp = 0;
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  useEffect(() => {
    if (isClear) navigate("/dashboard");
  }, [isClear]);
  useEffect(() => {
    if (projectData?.speech_to_text) {
      const realTimeArr = roundGraphObjArr.flatMap((value) => {
        return value.emotion;
      });

      const emotionArr: any = Object.values(
        convertToTimestampFormat(report.emotion_analysis_llama)
      ).flat();
      roundGraphArr = [...realTimeArr];

      setRowCalcWithTotalNumOfEmotion(emotionArr);
    }
  }, [roundGraphObjArr, isLoading]);
  useEffect(() => {
    if (sentiments.length > 0 && data[0] !== sentiments[0]) {
      sentiments = [...sentimentArr];
      setSentimentAttribute([...sentiments]);
    }
  }, [sentimentArr, isLoading]);
  useEffect(() => {
    viewSpeech = "";
    punctuation = "";
    audioReq(false);

    getProjectReq(id).then((res: any) => {
      setProjectData(res);

      const graphData = transformDataToGraphFormat(res?.true_value_score) || [];

      setScore(graphData || []);
      const timeStampArr = Object.entries(
        findClosestTimestamp(res?.speech_to_text, res?.true_value_score)
      ).map(([key, val]) => Number(Number(val)));

      const thumbnailArr = findNearestTimestampsFromThumbnails(
        res?.thumbnail?.files,
        timeStampArr
      );

      setOpinionSnippets(thumbnailArr);
      setLoading(false);
    });

    clearInterval(interval);
    audioReq(false);
  }, []);

  const speechData = {};
  if (projectData?.speech_to_text?.data) {
    for (const key in projectData?.speech_to_text.data) {
      speechData[Number(key).toFixed(0)] = speech_to_text.data[key];
    }
  }

  const handleVideoPlay = async (e, timestamp) => {
    roundGraphArr = [...roundGraphArr];
    sentiments = [];
    setSentimentArr([...sentimentArr]);
    setSentimentAttribute([...sentimentAttribute]);
    roundGraphObjArr = [...roundGraphObjArr];
    setClick(`click${e.timeStamp}`);

    if (!videoPlayed) {
      videoRef?.current?.addEventListener("timeupdate", (e) => {
        const currentTimeStampVideo = Number(
          videoRef?.current?.currentTime?.toFixed(0)
        );
        if (prevTimeStamp !== currentTimeStampVideo) {
          prevTimeStamp = currentTimeStampVideo;
          setEmotion(thumbnail.files[currentTimeStampVideo]?.emotion);
        }
      });
    }

    videoRef.current.muted = true;

    videoRef?.current?.play();

    setPlay(true);
    let count = timestamp.toFixed(0) || 0;
    viewSpeech = viewSpeech;
    punctuation = punctuation;

    interval = setInterval(() => {
      if (speechData[count]) {
        if (viewSpeech !== "") {
          prevViewSpeech += viewSpeech;
        }
        if (punctuation !== "") {
          prevPunctuation += punctuation;
        }
        viewSpeech = speechData[count].text;

        punctuation = speechData[count].text.replace(
          speechData[count].adjectives[0],
          `<span class="highlight-punctuation">${speechData[count].adjectives[0]}</span>`
        );
        if (speechData[count].adjectives[0]) {
          sentiments.push(speechData[count].adjectives[0]);
        }

        const emotionArr: any = Array.from(
          convertToTimestampFormat(report.emotion_analysis_llama)[count]
        );
        roundGraphObjArr.push({
          ...speechData[count],
          emotion: emotionArr.flat() !== -1 ? emotionArr.flat() : [],
        });
        setRoundGraphObjArr([...roundGraphObjArr]);
        setSentimentArr([...sentimentArr, ...sentiments]);
      }
      count++;
      const translationDiv = document.getElementById(
        "translation"
      ) as HTMLDivElement;
      translationDiv.scrollTop = translationDiv?.scrollHeight;
      const punctuationDiv = document.getElementById(
        "punctuation"
      ) as HTMLDivElement;
      punctuationDiv.scrollTop = punctuationDiv?.scrollHeight;
    }, 1000);
  };

  const data = [
    { x: 10, y: 100, radius: 20, value: 10, text: "abc" },
    { x: 50, y: 80, radius: 20, value: 8, text: "def" },
    { x: 90, y: 70, radius: 20, value: 7, text: "ghi" },
    { x: 130, y: 50, radius: 20, value: 5, text: "jkl" },
    { x: 160, y: 80, radius: 20, value: 8, text: "def" },
    { x: 180, y: 50, radius: 20, value: 5, text: "jkl" },
    { x: 200, y: 80, radius: 20, value: 8, text: "def" },
  ];

  const handleSend = async (e) => {
    try {
      const date = new Date();
      setCurrentMessage("");

      setChats((prevChats) => [
        ...prevChats,
        { message: e, role: "user", createAt: date.getTime() },
        { message: "", role: "ai", createAt: date.getTime() },
      ]);
      const res: any = await messageReq({
        chat: [
          ...chats,
          { message: e, role: "user", createAt: date.getTime() },
        ],
        id,
      });

      const message = JSON.parse(res);

      setChats((prevChats) => {
        const updatedChats = [...prevChats];
        updatedChats[updatedChats.length - 1] = {
          message: message.reply,
          role: "ai",
          createAt: date.getTime(),
          ts: message.ts,
        };
        return updatedChats;
      });
      setSendBtnDisabled(false);
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  return (
    <>
      {isLoading || !isAudioReady ? (
        <Overlay blur={15} bg={"white"} center h={"100vh"}>
          <Loader variant="bars" color="#001e6b" />
        </Overlay>
      ) : null}
      {!isLoading && (
        <>
          <Box
            p={5}
            m={0}
            style={{
              textAlign: "left",
              backgroundColor: "#cfcdc8",
              overflow: `${isAudioReady ? "visible" : "hidden"}`,
            }}
            h={"100vh"}
          >
            <Grid p={0} m={0}>
              <Col p={0} py={4}>
                <Box>
                  <Flex align={"center"}>
                    <Box
                      style={{
                        maxWidth: "6.25rem",
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <img
                        src={require("../../assets/images/logo.png")}
                        width={250}
                        height={80}
                      />
                    </Box>
                    <Box
                      py={10}
                      style={{
                        backgroundColor: "white",
                        height: "100%",
                        maxHeight: "100px",
                        width: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <Flex
                        h={74}
                        className="videoFrameFlex"
                        justify={"space-between"}
                      >
                        <Container
                          size={190}
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginInline: "50px",
                            marginLeft: "10px",
                          }}
                          p={0}
                        >
                          <ActionIcon
                            className="back-btn"
                            onClick={(e) => {
                              setOpen((prev) => (prev = false));
                              setCurrentMessage("");
                              setChats([]);
                              setShiftPress(false);
                              setRoundGraphObjArr([]);
                              setSendBtnDisabled(false);
                              setSentimentArr([]);
                              setRowCalcWithTotalNumOfEmotion(
                                (prev) => (prev = [])
                              );
                              setVideoPlayed(false);
                              setTextInterval(0);
                              setPunctuationInterval(0);
                              setTime(0);
                              setClick("");
                              setSentimentAttribute([]);
                              setEmotion("");
                              setScore([]);
                              viewSpeech = "";
                              prevViewSpeech = "";
                              prevPunctuation = "";
                              punctuation = "";
                              roundGraphArr = [];
                              sentiments = [];
                              videoRef.current.currentTime = 0;
                              videoRef.current.pause();
                              setPlay(false);
                              clearInterval(interval);
                              setIsClear(true);
                            }}
                          >
                            <ReplySharpIcon style={{ fill: "#001e6b" }} />
                          </ActionIcon>
                          <Text fw={600} ml={20}>
                            UNPROCESSED VIDEO
                          </Text>
                        </Container>

                        <CarouselFrame
                          img={thumbnail.files}
                          handlePlay={(e, timestamp) => {
                            videoRef.current.currentTime = timestamp;
                            setTime(timestamp);
                            setVideoPlayed(false);
                            setPlay(true);
                            clearInterval(interval);
                            handleVideoPlay(e, timestamp);
                          }}
                        />
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              </Col>
            </Grid>
            <Grid p={0} m={0}>
              <Col span={6} p={0} pr={4} py={4} m={0}>
                <Box>
                  <Container bg={"#001e6b"} px={8} maw={"100%"}>
                    <Flex justify={"space-between"} align="center">
                      <Title order={6} color="white">
                        VIDEO
                      </Title>
                      <MenuIcon
                        style={{ marginRight: "0px", color: "white" }}
                      />
                    </Flex>
                  </Container>

                  <Box
                    style={{
                      backgroundColor: "white",
                      width: "100%",
                      height: "274px",
                    }}
                  >
                    <Flex>
                      <Box
                        p={10}
                        py={30}
                        style={{ width: "100%", maxWidth: "300px" }}
                      >
                        <Title order={3}>{projectData.projectName}</Title>
                        <Box h={150} maw={200} w={"100%"}>
                          <Text>{projectData.projectDescription}</Text>
                        </Box>
                      </Box>
                      <Flex
                        justify={"center"}
                        align={"center"}
                        pos={"relative"}
                        h={"274px"}
                        w={"470px"}
                      >
                        <video
                          id="video"
                          className="videoCntr"
                          ref={videoRef}
                          src={video_url}
                          height={274}
                          width={470}
                          onClick={(e: any) => {
                            if (!videoPlayed) {
                              clearInterval(interval);
                              setPlay(false);
                              videoRef.current.pause();
                              videoRef.current.currentTime = Number(
                                e.target.currentTime
                              );
                              setTime(videoRef.current.currentTime);
                              setVideoPlayed(true);
                            } else if (videoPlayed) {
                              setPlay(true);
                              setVideoPlayed(false);
                              videoRef.current.currentTime = Number(
                                e.target.currentTime
                              );
                              setTime(videoRef.current.currentTime);
                              clearInterval(interval);
                              handleVideoPlay(e, e.target.currentTime);
                            }
                          }}
                          style={{
                            width: "100%",
                            position: "absolute",
                            top: "0px",
                            left: "0px",
                          }}
                          onLoadedMetadata={(e: any) => {
                            if (speech_to_text) {
                              const textArr = [];
                              const PuncArr = [];
                              videoRef.current.duration;
                              for (const key in speech_to_text?.data) {
                                textArr.push(speech_to_text.data[key]?.text);
                              }
                              for (const key in speech_to_text?.data) {
                                PuncArr.push(
                                  speech_to_text.data[key]?.adjectives
                                );
                              }
                              ["12.619999885559082"];

                              const punctuationExtraText =
                                PuncArr.flatMap((val) => val).length * 43;

                              setTextInterval(
                                textArr.toString().length / e.target.duration
                              );
                              setPunctuationInterval(
                                (textArr.toString().length +
                                  punctuationExtraText) /
                                  e.target.duration
                              );
                            }
                          }}
                        />

                        {!videoPlayed ? (
                          <Image
                            src={Pause}
                            classNames={{ root: "playBtn" }}
                            width={50}
                            height={50}
                            style={{ pointerEvents: "none" }}
                          ></Image>
                        ) : (
                          <Image
                            src={Play}
                            width={50}
                            height={50}
                            classNames={{ root: "pauseBtn" }}
                            style={{ pointerEvents: "none" }}
                          ></Image>
                        )}
                      </Flex>
                    </Flex>
                  </Box>
                </Box>
              </Col>
              <Col span={6} p={0} pl={4} py={4} m={0}>
                <Box m={0} p={0}>
                  <Grid p={0} m={0}>
                    <Col span={6} p={0} pr={4} pb={4} m={0}>
                      <Box
                        style={{
                          backgroundColor: "white",
                          height: "100%",
                          maxHeight: "144.5px",
                        }}
                      >
                        <Box bg={"#001e6b"} px={7}>
                          <Flex justify={"space-between"} align="center">
                            <Title order={6} color="white">
                              TRANSLATION
                            </Title>
                            <MenuIcon
                              style={{ marginRight: "1px", color: "white" }}
                            />
                          </Flex>
                        </Box>

                        <div
                          style={{
                            width: "100%",
                            height: "120px",
                            maxHeight: "120px",
                            overflow: "scroll",
                            padding: "5px",
                          }}
                          id="translation"
                        >
                          <TextTyped
                            text={viewSpeech}
                            isPlay={click}
                            interval={textInterval}
                            prevText={prevViewSpeech}
                          />
                        </div>
                      </Box>
                    </Col>
                    <Col span={6} p={0} pl={4} pb={4} m={0}>
                      <Box
                        style={{
                          backgroundColor: "white",
                          height: "100%",
                          maxHeight: "144.5px",
                        }}
                      >
                        <Box bg={"#001e6b"} px={7}>
                          <Flex justify={"space-between"} align="center">
                            <Title order={6} color="white">
                              PUNCTUATION
                            </Title>
                            <MenuIcon
                              style={{ marginRight: "1px", color: "white" }}
                            />
                          </Flex>
                        </Box>
                        <Box>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              maxHeight: "120px",
                              overflow: "scroll",
                              padding: "5px",
                              scrollBehavior: "smooth",
                              wordBreak: "break-word",
                            }}
                            id="punctuation"
                          >
                            <PunctuationTyped
                              text={punctuation}
                              isPlay={click}
                              interval={punctuationInterval}
                              prevText={prevPunctuation}
                            />
                          </div>
                        </Box>
                      </Box>
                    </Col>
                    <Col
                      span={6}
                      p={0}
                      pr={4}
                      pt={4}
                      m={0}
                      style={{ overflow: "hidden" }}
                    >
                      <Box
                        style={{
                          backgroundColor: "white",
                          height: "100%",
                          maxHeight: "144.5px",
                          overflow: "hidden",
                          width: "100%",
                        }}
                      >
                        <Box bg={"#001e6b"} px={7}>
                          <Flex justify={"space-between"} align="center">
                            <Title order={6} color="white">
                              SENTIMENT
                            </Title>
                            <MenuIcon
                              style={{ marginRight: "1px", color: "white" }}
                            />
                          </Flex>
                        </Box>
                        <Box h={"100%"}>
                          <RoundChart
                            graphData={roundGraphArr}
                            rowGraphData={rowCalcWithTotalNumOfEmotion}
                          />
                        </Box>
                      </Box>
                    </Col>
                    <Col span={6} p={0} pl={4} pt={4} m={0}>
                      <Box
                        style={{
                          backgroundColor: "white",
                          height: "100%",
                          maxHeight: "144.5px",
                        }}
                      >
                        <Box bg={"#001e6b"}>
                          <Flex justify={"space-between"} align="center">
                            <Title order={6} color="white">
                              &nbsp;&nbsp;ATTRIBUTE
                            </Title>
                            <MenuIcon
                              style={{ marginRight: "1px", color: "white" }}
                            />
                          </Flex>
                        </Box>
                        <Box
                          className="sentiment-parent"
                          style={{
                            height: "120.5px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            id="sentimentSection"
                            style={{
                              overflow: "scroll",
                              height: "120px",
                            }}
                          >
                            <Sentiment data={sentimentAttribute} />
                          </div>
                        </Box>
                      </Box>
                    </Col>
                  </Grid>
                </Box>
              </Col>
            </Grid>
            <Grid p={0} m={0}>
              <Col span={6} p={0} m={0} style={{ width: "100%" }}>
                <Grid p={0} m={0}>
                  <Col
                    span={5}
                    p={0}
                    pr={4}
                    pt={4}
                    m={0}
                    style={{ width: "100%" }}
                  >
                    <Box m={0} p={0}>
                      <Col
                        span={12}
                        p={0}
                        pb={4}
                        m={0}
                        style={{ width: "100%" }}
                      >
                        <Box
                          style={{
                            backgroundColor: "white",
                            height: "100%",
                            maxHeight: "360px",
                          }}
                        >
                          <Box bg={"#001e6b"} px={7}>
                            <Flex justify={"space-between"} align="center">
                              <Title order={6} color="white">
                                OPINION SNIPPETS
                              </Title>
                              <MenuIcon
                                style={{ marginRight: "1px", color: "white" }}
                              />
                            </Flex>
                          </Box>
                          <Box
                            h={"100%"}
                            mah={"323px"}
                            display={"flex"}
                            style={{
                              justifyContent: "start",
                              alignItems: "end",
                              paddingInline: "5px",
                              overflow: "hidden",
                              height: "139px",
                            }}
                          >
                            <Carousel
                              withIndicators
                              height={120}
                              slideSize="10%"
                              slideGap="xs"
                              align="start"
                              slidesToScroll={1}
                              styles={{ root: { width: "24.1rem" } }}
                            >
                              {opinionSnippets.map((link: any, ind) => {
                                return (
                                  <Carousel.Slide key={ind}>
                                    <div>
                                      <img
                                        src={link}
                                        style={{
                                          width: "145px",
                                          height: "100px",
                                        }}
                                      />
                                    </div>
                                  </Carousel.Slide>
                                );
                              })}
                            </Carousel>
                          </Box>
                        </Box>
                      </Col>
                      <Col span={12} p={0} pt={4} m={0}>
                        <Box
                          style={{
                            backgroundColor: "white",

                            height: "157px",
                          }}
                        >
                          <Box bg={"#001e6b"} px={7}>
                            <Flex justify={"space-between"} align="center">
                              <Title order={6} color="white">
                                VOICE MODULATION
                              </Title>
                              <MenuIcon
                                style={{ marginRight: "1px", color: "white" }}
                              />
                            </Flex>
                          </Box>
                          <Wave
                            path={audio_url}
                            isPlay={isPlay}
                            timeStamp={time}
                            click={click}
                          />
                        </Box>
                      </Col>
                    </Box>
                  </Col>
                  <Col span={7} p={0} px={4} pt={4} m={0}>
                    <Box
                      style={{
                        backgroundColor: "white",
                        height: "100%",
                        maxHeight: "352px",
                      }}
                    >
                      <Box bg={"#001e6b"} px={7}>
                        <Flex justify={"space-between"} align="center">
                          <Title order={6} color="white">
                            COMPUTER VISION ANALYSIS
                          </Title>
                          <MenuIcon
                            style={{ marginRight: "1px", color: "white" }}
                          />
                        </Flex>
                      </Box>
                      <Box h={"100%"}>
                        <VisionAnalysis
                          path={video_url}
                          timeStamp={time}
                          isPlay={isPlay}
                          emotion={emotion}
                          click={click}
                        />
                      </Box>
                    </Box>
                  </Col>
                </Grid>
              </Col>
              <Col span={6} p={0} py={4} pl={4} m={0} style={{ width: "100%" }}>
                <Box
                  style={{
                    backgroundColor: "white",
                    height: "100%",
                    maxHeight: "352px",
                  }}
                >
                  <Box bg={"#001e6b"} px={7}>
                    <Flex justify={"space-between"} align="center">
                      <Title order={6} color="white">
                        TRUE VALUE (TV)
                      </Title>
                      <MenuIcon
                        style={{ marginRight: "1px", color: "white" }}
                      />
                    </Flex>
                  </Box>
                  <Box
                    h={"100%"}
                    mah={"323px"}
                    display={"flex"}
                    style={{
                      justifyContent: "start",
                      alignItems: "end",
                      paddingBottom: "4px",
                      paddingLeft: "5px",
                      overflow: "hidden",
                    }}
                  >
                    <Graph data={trueValueScore} />
                  </Box>
                </Box>
              </Col>
            </Grid>
          </Box>
          <Dialog
            w={550}
            opened={isOpen}
            onClose={() => setOpen(false)}
            withCloseButton
            transition={"slide-left"}
            styles={{
              root: {
                borderRadius: "22px",
                overflow: "hidden",
              },
              closeButton: {
                right: "2px",
                top: "2px",
              },
            }}
          >
            <Container
              size="sm"
              style={{
                height: "calc(100vh - 80px)",
                paddingTop: "20px",
                position: "relative",
              }}
            >
              <Box
                style={{
                  position: "absolute",
                  backgroundColor: "#f5f5f5",
                  top: "0px",
                  left: "0px",
                  height: "calc(92vh - 100px)",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column-reverse",
                  marginBottom: "15px",
                  paddingBottom: "7px",
                  overflowY: "scroll",
                  borderRadius: "10px",
                }}
              >
                {chats
                  .slice()
                  .reverse()
                  .map((val, ind) => {
                    return (
                      <Fragment key={ind}>
                        {val?.role === "ai" && val.message === "" ? (
                          <Skeleton height={40} m={7} width="66%" radius="sm" />
                        ) : (
                          <Box>
                            <Box
                              className={`${
                                val?.role !== "user"
                                  ? "ai-message-box"
                                  : "user-message-box"
                              }`}
                              style={{
                                float: val?.role !== "user" ? "left" : "right",
                                margin: "7px",
                                wordBreak: "break-word",
                                height: "auto",
                                minHeight: "20px",
                                maxWidth: "66%",
                                padding: "10px",
                                borderRadius: "7px",
                                fontSize: "0.9rem",
                                lineHeight: "1.1rem",
                              }}
                            >
                              {val.message}
                              {val?.role === "ai" && (
                                <Flex
                                  className="ai-jump-video-contr"
                                  w={"100%"}
                                  h={"100%"}
                                  justify={"left"}
                                  align={"end"}
                                  pl={20}
                                  pb={10}
                                  onClick={(e) => {
                                    videoRef.current.pause();

                                    videoRef.current.currentTime = val.ts;

                                    setTime(val.ts);
                                    setVideoPlayed(false);
                                    setPlay(true);
                                    clearInterval(interval);
                                    handleVideoPlay(e, val.ts);
                                  }}
                                >
                                  <Text>
                                    {`Jump to video at ${convertToMinutesAndSeconds(
                                      Number(val.ts.toFixed(0))
                                    )}`}
                                  </Text>
                                </Flex>
                              )}
                            </Box>
                          </Box>
                        )}
                      </Fragment>
                    );
                  })}
              </Box>
              <Box
                style={{
                  position: "fixed",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  bottom: "0",
                  left: "0",
                  width: "100%",
                  backgroundColor: "#001e6b",
                  padding: "20px",
                  boxShadow:
                    "0 -1px 3px rgba(0,0,0,0.12), 0 -1px 2px rgba(0,0,0,0.24)",
                }}
              >
                <Textarea
                  placeholder="Generate message..."
                  minRows={1}
                  value={currentMessage}
                  autosize={false}
                  style={{
                    marginBottom: "10px",
                    width: "100%",
                    marginRight: "5px",
                  }}
                  onChange={(e) => setCurrentMessage(e.target.value)}
                  onKeyUp={(e) => {
                    if (e.key === "Shift") {
                      setShiftPress(false);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Shift") {
                      setShiftPress(true);
                    }
                    if (e.key === "Enter" && !isShiftPress) {
                      e.preventDefault();
                      if (!isSendBtnDisabled) {
                        handleSend(currentMessage);
                        setSendBtnDisabled(true);
                      }
                    }
                  }}
                />
                <Group position="right">
                  <Button
                    variant="light"
                    style={{
                      height: "44px",
                      marginBottom: "10px",
                      color: "#001e6b",
                    }}
                    onClick={(e) => {
                      if (!isSendBtnDisabled) {
                        handleSend(currentMessage);
                        setSendBtnDisabled(true);
                      }
                    }}
                  >
                    Send
                  </Button>
                </Group>
              </Box>
            </Container>
          </Dialog>
          {projectData?.isSaveEmbeddings && (
            <ActionIcon
              style={{
                position: "absolute",
                right: "30px",
                bottom: "30px",
              }}
              onClick={() => setOpen(true)}
            >
              <MessageRounded
                style={{ width: "60px", height: "70px", fill: "#001e6b" }}
              />
            </ActionIcon>
          )}
        </>
      )}
    </>
  );
}

export default ProjectDetail;
function readStreamAsText(response: any) {
  throw new Error("Function not implemented.");
}
