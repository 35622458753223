/** @format */

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <MantineProvider withNormalizeCSS withGlobalStyles>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Notifications />
        <App />
      </PersistGate>
    </Provider>
  </MantineProvider>
  // </React.StrictMode>
);
