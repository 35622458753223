/** @format */

import * as Yup from "yup";
const projectValidationSchema = (val) => {
  return Yup.object().shape({
    projectName: Yup.string().required("Project name is required"),
    // type: Yup.string().required("Project type is Required"),
    projectSrc: Yup.string().required("Please Add File or Link"),
    description: Yup.string()
      .max(200, "Character is Greater then 200 ")
      .required("Add Description"),
  });
};
export default projectValidationSchema;
