/** @format */

import { green } from "@mui/material/colors";
import React from "react";
import "./style.css";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { emotionBreakdownForGraph } from "../../feature/projectDetail/helpers/functions";
interface props {
  graphData?: any;
  total?: any;
  rowGraphData?: any;
  classes?: any;
}

const RoundChart = (props: props) => {
  const { graphData, rowGraphData } = props;

  const totalVal = rowGraphData.length;
  const graph = emotionBreakdownForGraph(graphData, totalVal).map((val) => ({
    name: val.emotion,
    percentage: val?.count,
  }));

  return (
    <div
      className="roundChart-wrapper"
      style={{
        width: "100%",
        maxHeight: "119px",
        height: "100%",
        display: "flex",
        justifyContent: "start",
        overflowY: "scroll",
        transition: "all 1s ease",
        paddingTop: "15px",
      }}
    >
      {graph.map((graph, ind) => {
        return (
          String(graph?.name).toLowerCase() !== "null" && (
            <div
              key={ind}
              style={{
                width: "57px",
                height: "57px",
                marginInline: "14px",
                transition: "all 1s ease",
              }}
            >
              <div
                className="roundGraph-contr"
                style={{ width: "fit-content", marginInline: "5px" }}
              >
                <CircularProgressbarWithChildren
                  value={graph.percentage}
                  styles={buildStyles({
                    strokeLinecap: "round",
                    pathColor: "#001e6b",
                    trailColor: "#b4b2b29c",
                  })}
                >
                  <strong style={{ fontSize: "17px", marginBottom: "20px" }}>
                    {`${graph.percentage}%`}
                  </strong>
                </CircularProgressbarWithChildren>
                <p
                  style={{
                    paddingInline: "5px",
                    textAlign: "center",
                    wordBreak: "break-word",
                    width: "75px",
                    fontSize: "12px",
                  }}
                >
                  {graph?.name}
                </p>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default RoundChart;
