const Snippets = ({
  snippets = [],
  classes,
}: {
  snippets: any;
  classes: any;
}) => {
  const tagClass = classes;
  return (
    <div className={tagClass.snippet}>
      <div className={tagClass.title}>
        <h1>
          <u>Top Highlights</u>
        </h1>
      </div>
      <div className={tagClass.cardRoot}>
        {snippets
          .filter((f: { thumbnail: any }) => f || f?.thumbnail)
          .map((item, index) => (
            <div key={index} className={tagClass.card}>
              <div className={tagClass.thumbnailRoot}>
                <img
                  src={item.thumbnail}
                  alt={`img${index}`}
                  className={tagClass.thumbnail}
                />
              </div>
              <div className={tagClass.detailRoot}>
                <p className={tagClass.detail}>{item.text}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default Snippets;
