/** @format */

import {
  Anchor,
  Box,
  Button,
  Card,
  Center,
  Container,
  Space,
  Text,
  TextInput,
  useMantineTheme,
} from "@mantine/core";

import PersonIcon from "@mui/icons-material/Person";
import GoogleIcon from "../../component/common/GoogleIcon";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useFormik } from "formik";
import { useState } from "react";
import { loginSchema } from "./validation";
import { color } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { LoginAction } from "./redux";
import { postLoginReq } from "./service/service";

const intialvalue = {
  username: "",
  password: "",
};

export default function login() {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { loginHandleAction } = bindActionCreators(LoginAction, dispatch);
  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,

    values,
  } = useFormik({
    initialValues: intialvalue,
    validationSchema: loginSchema,
    onSubmit: (e) => {
      const loginData = { email: e.username, password: e.password };
      postLoginReq(loginData)
        .then((res: any) => {
          navigate("/dashboard");
          localStorage.setItem("access_token", res.accessToken);
          loginHandleAction(true);
        })
        .catch((err) => {
          setError(err.response.data.message);
        });
    },
  });

  return (
    <Box>
      <Center style={{ width: "100%", height: "100vh" }}>
        <Container p={10} style={{ width: "100%", maxWidth: "400px" }}>
          <Card
            radius={10}
            shadow={"lg"}
            style={{
              boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            p={30}
          >
            <Box my={10}>
              <Center>
                <Text color={theme.colors.blue[4]} weight={700}>
                  Welcome to Vidopix
                </Text>
              </Center>
            </Box>
            <form onSubmit={handleSubmit}>
              <TextInput
                my={7}
                mx={10}
                name="username"
                value={values.username}
                onBlur={handleBlur}
                onChange={handleChange}
                label={
                  <p style={{ marginBottom: "6px", fontWeight: "bold" }}>
                    Username
                  </p>
                }
                error={
                  touched.username && errors.username ? errors.username : null
                }
                rightSection={
                  <PersonIcon
                    style={{
                      color:
                        theme.colorScheme == "dark"
                          ? theme.colors.gray[6]
                          : theme.colors.gray[8],
                    }}
                  />
                }
              />
              <TextInput
                my={7}
                mx={10}
                value={values.password}
                type={isShowPassword ? "text" : "password"}
                name="password"
                onBlur={handleBlur}
                error={
                  touched.password && errors.password ? errors.password : null
                }
                onChange={handleChange}
                label={
                  <p style={{ marginBottom: "6px", fontWeight: "bold" }}>
                    Password
                  </p>
                }
                rightSection={
                  !isShowPassword ? (
                    <VisibilityOffIcon
                      style={{
                        color:
                          theme.colorScheme == "dark"
                            ? theme.colors.gray[6]
                            : theme.colors.gray[8],
                      }}
                      onClick={() =>
                        isShowPassword
                          ? setIsShowPassword(false)
                          : setIsShowPassword(true)
                      }
                    />
                  ) : (
                    <VisibilityIcon
                      style={{
                        color:
                          theme.colorScheme == "dark"
                            ? theme.colors.gray[2]
                            : theme.colors.gray[8],
                      }}
                      onClick={() =>
                        isShowPassword
                          ? setIsShowPassword(false)
                          : setIsShowPassword(true)
                      }
                    />
                  )
                }
              />
              {error !== "" && (
                <Center>
                  <Text color="red">{error}</Text>
                </Center>
              )}
              <Center mt={40} mx={5} mb={50}>
                <Button
                  fullWidth
                  type="submit"
                  variant="filled"
                  style={{ fontSize: "18px" }}
                >
                  Login
                </Button>
              </Center>
            </form>
          </Card>
        </Container>
      </Center>
    </Box>
  );
}
