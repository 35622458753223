import { ResponsivePie } from "@nivo/pie";
import pdfStyle from "./pdf.module.css";

const MoodDisplayGraph = ({ data }: { data: any }) => {
  const graphData: any[] = Object.entries(data).map(([key, value]) => ({
    id: key,
    label: key === "NEU" ? "Neutral" : key === "POS" ? "Positive" : "Negative",
    value: value,
  }));
  const updatedData = graphData?.some((innerItem) => innerItem.id === "NEU")
    ? graphData
        .map((item) => {
          if (item?.id === "NEU") {
            // Retrieve the value for NEU and divide it by 2
            const halfNeuValue = item?.value / 2;

            return [
              { ...item, value: halfNeuValue }, // Return the original NEU item
              graphData?.some((innerItem) => innerItem.id === "POS")
                ? {
                    ...graphData.find((innerItem) => innerItem.id === "POS"),
                    value:
                      graphData.find((innerItem) => innerItem.id === "POS")
                        .value +
                      halfNeuValue / 2,
                  }
                : {
                    id: "POS",
                    label: "Positive",
                    value: halfNeuValue / 2,
                  },
              graphData?.some((innerItem) => innerItem.id === "NEG")
                ? {
                    ...graphData.find((innerItem) => innerItem.id === "NEG"),
                    value:
                      graphData.find((innerItem) => innerItem.id === "NEG")
                        .value +
                      halfNeuValue / 2,
                  }
                : {
                    id: "NEG",
                    label: "Negative",
                    value: halfNeuValue / 2,
                  },
            ];
          }
        })
        .flat()
        .filter((val) => val)
    : graphData;
  return (
    <div style={{ width: "100%", marginBlock: "30px" }}>
      <div className={pdfStyle.titleContr}>
        <h1>
          <u>Emotion Bias</u>
        </h1>
      </div>
      <div style={{ width: "790px", height: "260px" }}>
        <ResponsivePie
          data={updatedData}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5} // Adjust inner radius for a donut chart
          padAngle={1} // Space between slices
          cornerRadius={3} // Rounded corners for slices
          colors={{ scheme: "nivo" }} // Color scheme
          borderWidth={1} // Width of the border between slices
          borderColor={{ from: "color", modifiers: [["brighter", 1.2]] }} // Border color
          arcLinkLabelsTextColor="#d6cdcd" // Color of the labels
          arcLinkLabelsColor="#ffffff"
          arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }} // Color for arc labels
          enableArcLinkLabels={true} // Enable linking labels
          enableArcLabels={true} // Enable labels on arcs
          animate={true} // Enable animations
        />
      </div>
    </div>
  );
};
export default MoodDisplayGraph;
