/** @format */

import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "./App.css";
import Dashboard from "./feature/dashboard/dashboard";
import ProjectDetail from "./feature/projectDetail/projectDetail";

import Login from "./feature/login/login";
import Layout from "./feature/hoc/layout";
import { useSelector } from "react-redux";
import PdfLayout from "./component/pdf";

function App() {
  const isLogin = useSelector((state: any) => state.loginReducer.isLogin);

  const router = () => {
    if (isLogin) {
      return (
        <Routes>
          <Route path="/" element={<Navigate to={"/dashboard"} />} />
          <Route path="/dashboard" element={<Layout />}>
            <Route path="" element={<Dashboard />} />
          </Route>
          <Route path="/*" element={<Navigate to={"/dashboard"} />} />
          <Route path="projectDetail" element={<ProjectDetail />} />
          <Route path="/report/:id" element={<PdfLayout />} />
        </Routes>
      );
    } else {
      // lazy loading not needed for login, forget password, reset password APIs
      return (
        <Routes>
          <Route path="/" element={<Navigate to={"/login"} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<Navigate to={"/"} />} />
        </Routes>
      );
    }
  };

  return <BrowserRouter>{router()}</BrowserRouter>;
}
export default App;
