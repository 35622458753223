import Snippets from "./opinianSnippets";
import Summary from "./summery";
import pdfStyle from "./pdf.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { RefObject, useEffect, useRef, useState } from "react";
import { getReportReq } from "../../feature/dashboard/service/service";
import EmotionGraph from "./emotionGraph";
import { convertToTimestampFormat } from "../../feature/projectDetail/helpers/functions";
import { Button } from "@mantine/core";
import { useReactToPrint } from "react-to-print";
import MoodDisplayGraph from "./moodDesplayGraph";
const PdfLayout = () => {
  const [report, setReport] = useState<{
    summary: string;
    title: string;
    snippet: any[];
    emotion: any;
    emotion_graph: any;
  }>({ summary: "", title: "", snippet: [], emotion: {}, emotion_graph: {} });
  const urlParams = useParams();
  const reportRef = useRef() as unknown as RefObject<HTMLDivElement>;
  const handlePrint = useReactToPrint({ contentRef: reportRef });
  const navigate = useNavigate();
  const getReport = async () => {
    try {
      const response: any = await getReportReq(urlParams?.id);
      const report = await response?.report;
      const emotionGraph: any = convertToTimestampFormat(
        report?.emotion_analysis_llama
      );
      setReport({
        summary: report?.summary,
        title: report?.title,
        snippet: report?.opinion_snippet,
        emotion: emotionGraph,
        emotion_graph: report?.emotion_graph,
      });
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };
  useEffect(() => {
    getReport();
  }, []);
  return (
    <>
      <div
        style={{
          marginBlock: "20px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          paddingInline: "25px",
        }}
      >
        <Button variant="gradient" onClick={() => navigate("/dashboard")}>
          BACK
        </Button>
        <Button variant="gradient" onClick={() => handlePrint()}>
          PRINT
        </Button>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className={pdfStyle.pdfLayout} ref={reportRef}>
          <div className={pdfStyle.summarySection}>
            <Summary
              classes={{
                section1: pdfStyle.section1,
                img: pdfStyle.img,
                itemImg: pdfStyle.itemImg,
                item: pdfStyle.item,
              }}
              details={report?.summary || ""}
              title={report?.title || ""}
            />
          </div>
          <div className={pdfStyle.pageBreak} />
          <div>
            <EmotionGraph emotion={report?.emotion} />
          </div>
          <div className={pdfStyle.pageBreak} />
          <div>
            <Snippets
              snippets={report.snippet}
              classes={{
                snippet: pdfStyle.snippetContr,
                title: pdfStyle.titleContr,
                cardRoot: pdfStyle.cardContr,
                card: pdfStyle.card,
                thumbnailRoot: pdfStyle.thumbnailContr,
                thumbnail: pdfStyle.thumbnail,
                detailRoot: pdfStyle.detailContr,
                detail: pdfStyle.detail,
              }}
            />
          </div>
          <div className={pdfStyle.pageBreak} />
          <div>
            <MoodDisplayGraph data={report?.emotion_graph} />
          </div>
        </div>
        <br />
      </div>
    </>
  );
};
export default PdfLayout;
